import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { cryptoTheme } from 'common/src/theme/crypto';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Crypto/crypto.style';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Navbar from '../containers/Crypto/Navbar';
import Footer from '../containers/Crypto/Footer';
import SEO from '../components/seo';
import { AuthenticationProvider } from 'common/src/contexts/AuthenticationContext';
import BannerEmpty from '../containers/Crypto/BannerEmpty';
import Faq from '../containers/Crypto/Faq';
import ApiProvider from 'common/src/contexts/ApiContext';
import EnsureTCAreApproved from '../hoc/EnsureTCAreApproved';

export default props => {
  return (
    <ThemeProvider theme={cryptoTheme}>
      <ApiProvider>
        <AuthenticationProvider>
          <EnsureTCAreApproved>
            <Fragment>
              <SEO title="Faq | ArbCircle | Automated Bitcoin trading platform" />
              <ResetCSS />
              <GlobalStyle />
              <ContentWrapper>
                <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                  <DrawerProvider>
                    <Navbar path="/faq" />
                  </DrawerProvider>
                </Sticky>
                <BannerEmpty />
                <Faq />
                <Footer />
              </ContentWrapper>
            </Fragment>
          </EnsureTCAreApproved>
        </AuthenticationProvider>
      </ApiProvider>
    </ThemeProvider>
  );
};

import styled from 'styled-components';

const FaqWrapper = styled.section`
  margin-top: 110px;
  margin-bottom: 40px;
  z-index: 1;
  position: relative;
  li {
    list-style-type: disc !important;
   }
  @media (max-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 480px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  .containerClass {
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 94px 6px rgba(107, 83, 254, 0.1);
    padding: 80px 0;

    .contactdes {
      text-align: center;
      @media (max-width: 990px) {
        padding: 0 20px;
      }
    }
  }
  
  .accordionContainer {
    padding: 0 40px;
   }
  
  .accordionTitle {
    box-sizing: border-box;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding-left: 10px;
    font-size: 16px;
    color: #525f7f;
    font-family: Poppins;
    font-weight: 600;
    text-align: left;
    line-height: 27px;
  }
  
  .accordionBody {
    box-sizing: border-box;
    margin-bottom: 30px;
    margin-top: 0px;
    padding: 20px;
    max-width: 100%;
    font-size: 16px;
    color: #525f7f;
    font-weight: 400;
    line-height: 28px;

    img {
      max-width: 100%;
    }
  }
  
  .row {
    margin-top:30px;
  }

  .colleft {
    width: 50%;
    @media (max-width: 1440px) {
      width: 50%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .colright {
    width: calc(50% - 70px);
    margin-left: 70px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1440px) {
      width: calc(50% - 70px);
    }
    @media (max-width: 768px) {
      width: 100%;
      margin-left: 0;
      margin-top: 100px;
    }
    @media (max-width: 480px) {
      margin-top: 50px;
    }
    .readMoreSection {
      .readMore {
        font-size: 20px;
        font-weight: 500;
        font-family: 'Poppins';
        @media (max-width: 480px) {
          font-size: 16px;
        }
      }
    }
   
      @media (max-width: 480px) {
        display: none;
      }
    }
  }
`;

export { FaqWrapper };

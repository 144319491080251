import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Image from 'reusecore/src/elements/Image';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import ControlImage from 'common/src/assets/image/crypto/control.jpg';
import { FaqWrapper } from './faq.style';
import {
  Accordion,
  AccordionBody,
  AccordionItem,
  AccordionTitle,
} from 'common/src/components/Accordion';
import UserApiInfoWrapper, {
  SectionApiInfoWrapper,
} from '../UserApiInfo/userapi.style';
import Input from 'reusecore/src/elements/Input';

const Faq = ({ row, col, title, description, sectionSubTitle, cardArea }) => {
  return (
    <FaqWrapper id="control">
      <Box>
        <Container className="containerClass">
          <Box>
            <FeatureBlock
              title={<Heading {...title} />}
              description={<Text {...description} />}
            />
          </Box>

          <Accordion className="accordionContainer">
            <AccordionItem>
              <AccordionTitle className="accordionTitle">
                Why do you offer a software licence only for usd/btc?
              </AccordionTitle>
              <AccordionBody className="accordionBody">
                Bitcoin is the most liquid crypto currency and is the most
                important proxy in this new asset class. The biggest pool of
                liquidity is in the BTC/USD: a software that aims to capture
                trends needs liquidity to be in and out of the market with low
                market impact when there is a transaction. BTC/EUR or BTC/GBP
                have very low liquidity compared to the main pair BTC/USD. We
                may change our policy in the future depending on the market
                opportunity.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionTitle className="accordionTitle">
                Why do you offer a software licence connected only to Bitstamp?
              </AccordionTitle>
              <AccordionBody className="accordionBody">
                Bitstamp is one of the oldest and most trusted entities among
                exchanges with good liquidity for our software licence goal. We
                may add more exchanges in the future.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionTitle className="accordionTitle">
                Why do I need to create a USD account on Bitstamp to use your
                software licence?
              </AccordionTitle>
              <AccordionBody className="accordionBody">
                It is important for us that you create a fiat account in USD
                because we offer a software licence that you can use only for
                BTC/USD. If you create a EUR account you are not able to trade
                BTC/USD on Bitstamp, therefore you will be not able to use our
                software.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionTitle className="accordionTitle">
                Why do you provide a software tool that allows me to be only
                long or flat on BTC/USD?
              </AccordionTitle>
              <AccordionBody className="accordionBody">
                “Shorting” bitcoin is not a straightforward action on exchanges.
                We are bitcoin believers, so we didn’t design a software to go
                “short” the market. You can be either long or flat the market
                using our software.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionTitle className="accordionTitle">
                How do I transfer money in my Bitstamp account?
              </AccordionTitle>
              <AccordionBody className="accordionBody">
                For clients in the Euro area please select EU Bank SEPA
                transfer. For all the other clients please select International
                Bank. Then select U.S. dollar for account balance and insert the
                amount you want to transfer.
                <br />
                <br />
                <img src="https://arbcircle-assets.s3-eu-west-1.amazonaws.com/faq/faq7.png" />
                <br />
                <br />
                Once you click deposit you receive all the details for the bank
                transfer including the code to be included in the reason for the
                payment.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionTitle className="accordionTitle">
                What information do I need to share from my Bistamp account to
                use your software?
              </AccordionTitle>
              <AccordionBody className="accordionBody">
                Assuming you have a USD account on Bistamp, you need to create
                an API access in order to use our software tool on your account.{' '}
                <b>
                  We strongly recommend you create a sub account on bitstamp to
                  use our service, in this way you can track our service
                  independently from your manual activity that might happen in
                  the main account or in other sub accounts. In order to
                  appreciate our software licence, we recommend you don’t add
                  discretionary trades in the sub account.
                </b>
                <br />
                The steps to follow are the following:
                <br />
                <br />
                1) Create a sub account (call it arbcircle if you wish, our API
                is not relying on sub account name)
                <br />
                In account dashboard got to Sub Accounts -> Active Sub Accounts
                -> press Create New button
                <br />
                <br />
                <img src="https://arbcircle-assets.s3-eu-west-1.amazonaws.com/faq/faq6S1x1.png" />
                <br />
                <br />
                Insert the name you wish (for example arbcircle) and click
                Create Sub Account
                <br />
                <br />
                <img src="https://arbcircle-assets.s3-eu-west-1.amazonaws.com/faq/faq6S1x2.png" />
                <br />
                <br />
                You will see the new sub account created at the bottom of the
                page
                <br />
                <br />
                <img src="https://arbcircle-assets.s3-eu-west-1.amazonaws.com/faq/faq6S1x3.png" />
                <br />
                <br />
                2) Create API information to be shared on arbcircle internal
                user page
                <br />
                In Account dashboard go to Security -> API Access -> Click New
                API Key button
                <br />
                <br />
                <img src="https://arbcircle-assets.s3-eu-west-1.amazonaws.com/faq/faq6S2x1.png" />
                <br />
                <br />
                Switch on specific permissions for the sub account for the API
                access as highlighted below (WE WILL NEVER ASK YOU PERMISSION TO
                WITHDRAW MONEY):
                <br />
                <br />
                <img src="https://arbcircle-assets.s3-eu-west-1.amazonaws.com/faq/faq6S2x2.png" />
                <br />
                <br />
                Click enter and confirm through your two-factor authentication
                (if you have it) the selected details.
                <br />
                <br />
                <img src="https://arbcircle-assets.s3-eu-west-1.amazonaws.com/faq/faq6S2x3.png" />
                <br />
                <br />
                Once you create the API key, you need to provide in our internal
                client area the following information:
                <ul>
                  <li>your bitstamp id</li>
                  <li>
                    your key that you could find in the blue area in the picture
                    below
                  </li>
                  <li>
                    the secret key that you could find in the black area in the
                    picture below{' '}
                    <b>
                      (PLEASE SAVE IT SOMEWHERE THIS because once the API is
                      confirmed it becomes hidden)
                    </b>
                  </li>
                </ul>
                <br />
                <img src="https://arbcircle-assets.s3-eu-west-1.amazonaws.com/faq/faq6S2x4.png" />
                <br />
                <br />
                You click activate, you will receive a confirmation email to
                approve the new API. Once you approve it you will see the
                following screen on your account
                <br />
                <br />
                <img src="https://arbcircle-assets.s3-eu-west-1.amazonaws.com/faq/faq6S2x5.png" />
                <br />
                <br />
                Please insert the 3 codes (ID, Key, Secret) in our internal user
                page
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionTitle className="accordionTitle">
                How do I pay you for your software licence?
              </AccordionTitle>
              <AccordionBody className="accordionBody">
                You only pay if you make money is our motto. In case you are in
                profit at the end of the month, we will send an email with the
                amount you have to pay and our bank details. Once you pay we
                send the invoice/receipt.
                <br />
                <br />
                We currently support only bank transfers. In your internal area
                you select initially the currency you would like to use to pay,
                so you will receive payment requests in the currency you prefer
                among US Dollar, Swiss Franc, Euro or Sterling.
                <br />
                <br />
                At the moment if you want to pay in <b>Euro</b> you will rely on
                SEPA network if your account is in a SEPA country, this means
                you shouldn’t pay any fee for the transfer (unless your bank
                charges for euro bank transfers) and no currency conversion
                markup fee.
                <br />
                <br />
                If you are based in UK and you want to be charged in{' '}
                <b>Sterling</b>, the bank transfer is free and there is no
                currency conversion markup fee.
                <br />
                <br />
                For all the other cases you will rely on SWIFT network, this
                means your bank will charge a transfer fee and there is also a
                currency conversion markup typically.
              </AccordionBody>
            </AccordionItem>

            <AccordionItem>
              <AccordionTitle className="accordionTitle">
                How should I stop using your software licence?
              </AccordionTitle>
              <AccordionBody className="accordionBody">
                We recommend you stop our software from our internal area first.
                Another option is to cancel the API key you created on Bitstamp.
                Once you delete the API key on Bistamp if you want to use our
                software licence again you need to create another API key and
                insert the information in our client area. Keep in mind that
                once you stop the service our pricing will not take into account
                previous history and cannot be activated again until you pay any
                potential unpaid licence fee.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </Container>
      </Box>
    </FaqWrapper>
  );
};

// Transactions style props
Faq.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  readMoreTitle: PropTypes.object,
  btnStyle: PropTypes.object,
};

// Transactions default style
Faq.defaultProps = {
  // Transactions section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Transactions section col default style
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: 'center',
  },
  // Transactions section title default style
  title: {
    content: 'F.A.Q.',
    fontSize: ['20px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    textAlign: ['center', 'center'],
  },
  // Transactions section description default style
  description: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['30px', '30px', '35px', '35px', '45px'],
    maxWidth: ['100%', '100%', '100%', '100%', '430px'],
  },
  sectionSubTitle: {
    as: 'span',
    textAlign: 'left',
    fontSize: ['16px', '16px', '18px', '20px', '20px'],
    fontFamily: 'Poppins',
    fontWeight: '600',
    lineHeight: '27px',
    color: '#525f7f',
  },
};

export default Faq;
